import React, {useEffect, useState} from 'react';
import './CreditAdviceList.scss';
import TableCell from '@material-ui/core/TableCell';
import {toCalenderDateString, toDateString} from '../../services/DateUtils';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import {Link, useHistory} from 'react-router-dom';
import {Icon} from '@material-ui/core';
import Spinner from '../../components/Spinner/Spinner';
import {CreditAdviceStatus, CreditAdviceStatusLabels} from './models/CreditAdvice';
import {creditAdviceService} from './services/CreditAdviceService';
import {notificationService} from '../../components/Notifications/Notifications';
import {Pagination} from '../../components/Pagination/model/Pagination';
import TablePagination from '../../components/Pagination/TablePagination';
import {MdManageAccounts} from 'react-icons/md'
import {GiGears} from 'react-icons/gi'
import {FaFileInvoiceDollar} from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import {toAccountingTypeLiteral} from './CreditAdviceDetails';
import {CustomCheckbox} from '../purchases/components/PurchaseRow';
import BulkUpdateConfirmModal from './components/BulkUpdateConfirmModal';
import useModal from '../../hooks/UseModal';
import {CreditAdviceFilter} from './components/CreditAdviceFilter';
import SearchResultSummary from '../../components/SearchResultSummary/SearchResultSummary';
import moment from 'moment';
import {deriveApiUrl} from '../../infrastructure/DeriveApiUrl';
import {AccountingTypes} from '../vendor/models/PricingModel';

const CreditAdviceList = ({searchQuery = window.location.search}) => {
  const history = useHistory();

  const [creditAdvices, setCreditAdvices] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [pagination, setPagination] = useState(new Pagination());

  const [currentSearch, setCurrentSearch] = useState({searchParams: new URLSearchParams()})

  const queryFromUrl = new URLSearchParams(searchQuery);

  const [selectedCreditAdvices, setSelectedCreditAdvices] = useState([])

  const [logMessages, setLogMessages] = useState([]);

  const [modalToggled, toggleModal] = useModal()

  const [vendors, setVendors] = useState([])

  useEffect(() => {
    if (!queryFromUrl.has('searchStartDate')) {
      queryFromUrl.set('searchStartDate', toCalenderDateString((moment().startOf('month'))))
    }
    if (!queryFromUrl.has('searchEndDate')) {
      queryFromUrl.set('searchEndDate', toCalenderDateString((moment().endOf('month'))))
    }
    if (!queryFromUrl.has('page')) {
      queryFromUrl.set('page', '0')
    }
    executeCreditAdviceSearch(queryFromUrl)
  }, []);

  const executeCreditAdviceSearch = (searchParams) => {
    setLoading(true)

    const query = new URLSearchParams(searchParams)

    creditAdviceService.fetchCreditAdvices(query)
      .then((searchResponse) => {
        setCreditAdvices(searchResponse.content)
        setPagination(searchResponse.pagination)
        let urlSearchParams = new URLSearchParams(searchParams);
        setCurrentSearch({searchParams: urlSearchParams})
        history.push({search: `?${(urlSearchParams.toString())}`});
      })
      .catch(() => notificationService.error('Die Anfrage ist leider fehlgeschlagen. Probiere es erneut.'))
      .finally(() => {
        setLoading(false)
      });

    creditAdviceService.fetchVendorsForCreditAdvices(query)
      .then(response => setVendors(response))
      .catch(() => notificationService.error('Konnte Anbieter nicht laden, bitte aktualisiere die Seite.'))
  }

  const handlePageChange = (e) => {
    if (e.selected !== pagination.pageNumber) {
      currentSearch.searchParams.set('page', e.selected)
      executeCreditAdviceSearch(currentSearch.searchParams)
    }
  }

  const creditAdviceStatusLiteral = (state) => {
    return CreditAdviceStatusLabels[state];
  };

  const handleSelectedCreditAdvice = (entry) => {
    if (isSelected(entry)) {
      setSelectedCreditAdvices(prevSelected =>
        prevSelected.filter(selectedEntry =>
          selectedEntry.aggregateId.vendorKey !== entry.aggregateId.vendorKey && selectedEntry.aggregateId.serialNumber !== entry.aggregateId.serialNumber
        )
      )
    } else {
      setSelectedCreditAdvices(prevSelected => [...prevSelected, entry]);
    }
  }

  const isSelected = (entry) => {
    return selectedCreditAdvices.some(selectedEntry =>
      selectedEntry.aggregateId.vendorKey === entry.aggregateId.vendorKey && selectedEntry.aggregateId.serialNumber === entry.aggregateId.serialNumber)
  }

  const isAnyCreditAdviceSelected = () => {
    return !!selectedCreditAdvices.length
  }

  const showConfirmModal = () => {
    toggleModal()
  }

  const handleBatchStatusChange = () => {
    // Step 1: Create a callback function to update entry status
    const updateEntryStatus = (serialNumber) => {
      console.log(`Abrechnung für ${serialNumber} ist erstellt.`)
      const newLogMessage = `Abrechnung für ${serialNumber} ist erstellt.`;
      setLogMessages((prevMessages) => [...prevMessages, newLogMessage]);
    };
    creditAdviceService.bulkModifyStatus(selectedCreditAdvices, CreditAdviceStatus.BILLABLE, updateEntryStatus).then(failureCount => {
      if (!failureCount) {
        notificationService.success(`${selectedCreditAdvices.length} Gutschriften geändert.`);
        // Assuming creditAdvices is an array of objects with an identifier like id
        const updatedCreditAdvices = creditAdvices.map(entry => {
          const isSelected = selectedCreditAdvices.some(selectedEntry => selectedEntry.aggregateId.serialNumber === entry.aggregateId.serialNumber);
          if (isSelected) {
            // Modify the status property or any other property you need to update
            return {...entry, state: CreditAdviceStatus.BILLABLE};
          }
          return entry;
        });

        // Update the state with the modified data
        setCreditAdvices(updatedCreditAdvices);
      } else {
        notificationService.error(`Der Status ${failureCount} von ${selectedCreditAdvices.length} Gutschriften konnte nicht geändert werden.`);
      }
      setSelectedCreditAdvices([])
      toggleModal()
    })
  }

  const statusPills = (status) => {
    switch (status) {
      case CreditAdviceStatus.PRELIMINARY:
        return '';
      case CreditAdviceStatus.CREATING:
        return 'pill--blue';
      case CreditAdviceStatus.SHIPPABLE:
        return 'pill--yellow';
      case CreditAdviceStatus.BILLABLE:
        return 'pill--red';
      case CreditAdviceStatus.CLEARED:
        return 'pill--green';
      default: {
        return '';
      }
    }
  };

  function renderEditCreditAdviceIcon(entry) {
    return <>
      <CustomCheckbox
        className="creditadvicelist__checkbox"
        data-testid="creditadvicelist--checkbox"
        disabled={entry.state !== CreditAdviceStatus.SHIPPABLE}
        checked={isSelected(entry)}
        onClick={() => handleSelectedCreditAdvice(entry)}
      />
      <Link
        to={{pathname: `/creditadvices/edit/${entry.aggregateId.vendorKey}/${entry.aggregateId.serialNumber}`}}
        data-testid={`creditadvicelist--itemLink`}
      >
        <Icon className="creditadvicelist__icon--edit">edit</Icon>
      </Link>
    </>;
  }

  const renderBatchStatusIcon = (isBatch, isItemsCountEdited) => {
    if (!isBatch) return ''
    const tooltipTitle = isItemsCountEdited ? 'Manuell angepasst' : 'Automatisch erstellt'
    return <Tooltip title={tooltipTitle}>{isItemsCountEdited ?
      <span data-testid="creditadvice--batchStatusIcon__edited"><MdManageAccounts color={'orange'} size={25}/></span> :
      <span data-testid="creditadvice--batchStatusIcon__notEdited"><GiGears size={25}
                                                                            color="green"/></span>}</Tooltip>
  }

  const renderPreviewButton = (entry) => {
    let backendBaseUrl = deriveApiUrl(window.location.href);
    const link = `${backendBaseUrl}/usage-catalog/api/credit-advices/${entry.aggregateId.vendorKey}/${entry.aggregateId.serialNumber}/preview`;
    const btn = <>
      <Link
        to={{pathname: link}}
        target="_blank"
      >
        <div className="creditadvicelistitem__previewButton"
             data-testid="creditadvicelistitem--previewButton">
          <FaFileInvoiceDollar size={25}/>
        </div>
      </Link>
    </>;
    return isLocked(entry) && entry.accountingType !== AccountingTypes.USAGE_REPORT ? btn : <></>;
  };

  const isLocked = (entry) => {
    return [CreditAdviceStatus.CREATING, CreditAdviceStatus.SHIPPABLE, CreditAdviceStatus.BILLABLE, CreditAdviceStatus.CLEARED].includes(entry.state)
  }

  return (
    <>
      {isLoading && (<Spinner/>)}

      <BulkUpdateConfirmModal
        modalToggled={modalToggled}
        toggleModal={toggleModal}
        submitButtonText="Ausführen"
        cancelButtonText="Abbrechen"
        handleBatchStatusChange={handleBatchStatusChange}
        logMessages={logMessages}
      />

      <div className="creditadvicelist__wrapper" data-testid="creditadvicelist--container">
        <CreditAdviceFilter triggerSearch={executeCreditAdviceSearch} isBulkUpdateDisabled={isAnyCreditAdviceSelected}
                            showBulkUpdateConfirmModal={showConfirmModal} vendors={vendors}/>
        <SearchResultSummary totalCount={pagination.totalElements}/>
        <TableContainer component={Paper} data-testid="creditadvicelist--table">
          <Table aria-label="simple table">
            <TableHead className="table__head">
              <TableRow>
                <TableCell className="creditadvicelist__actionColumn"/>
                <TableCell align="left">Firma</TableCell>
                <TableCell align="left">Verwendungsmeldungsnummer</TableCell>
                <TableCell align="left">Abrechnungstyp</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Letzte Änderung</TableCell>
                <TableCell align="left">Zeitraum</TableCell>
                <TableCell align="left">Posten</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!isLoading && creditAdvices.length === 0 && (
                <TableRow key="noItems" hover={true} data-testid="creditadvicelist--noitems">
                  <TableCell align="center" colSpan={7} className="creditadvicelist__noCreditAdvicesMessage">Keine
                    Gutschriften vorhanden</TableCell>
                </TableRow>
              )}

              {!isLoading && creditAdvices.length > 0 && (
                creditAdvices.map((entry, index) => {
                  return (
                    <TableRow key={index} hover={true} data-testid="creditadvicelist--item">
                      <TableCell
                        style={{display: 'flex', alignItems: 'center'}}>{renderEditCreditAdviceIcon(entry)}</TableCell>
                      <TableCell data-testid="creditadvice--companyName">{entry.companyName}</TableCell>
                      <TableCell data-testid="creditadvice--id">{entry.aggregateId.serialNumber}</TableCell>
                      <TableCell
                        data-testid="creditadvice--accountingType">{toAccountingTypeLiteral(entry.accountingType)}</TableCell>
                      <TableCell
                        data-testid="creditadvice--batchStatusIcon">{renderBatchStatusIcon(entry.isBatch, entry.isItemsCountEdited)}</TableCell>
                      <TableCell>
                        <div className={`pill pill__creditAdviceStatus ${statusPills(entry.state)}`}>
                          {creditAdviceStatusLiteral(entry.state)}
                        </div>
                      </TableCell>
                      <TableCell>{toDateString(entry.lastModified)}</TableCell>
                      <TableCell>{toDateString(entry.accountingTimespan.start)} - {toDateString(entry.accountingTimespan.end)}</TableCell>
                      <TableCell>{entry.totalCount}</TableCell>
                      <TableCell>{renderPreviewButton(entry)}</TableCell>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!isLoading && creditAdvices.length > 0 && (
          <TablePagination pagination={pagination} handlePageChange={handlePageChange}/>
        )}
      </div>
    </>
  );
};

export default CreditAdviceList;
