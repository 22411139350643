import {CreditAdvice, CreditAdviceSummary} from '../models/CreditAdvice';
import {httpClient} from '../../../infrastructure/HttpClient';
import {handleHttpStatusCode} from '../../../infrastructure/HandleHttpStatusCode';
import {Pagination} from '../../../components/Pagination/model/Pagination';

class CreditAdviceService {
  static URL_MAPPING = '/usage-catalog/api/credit-advices';

  fetchCreditAdvices(query = new URLSearchParams()) {
    if (!(query instanceof URLSearchParams)) {
      throw new Error('query must be instance of URLSearchParams');
    }

    return httpClient
      .get(`${CreditAdviceService.URL_MAPPING}?${query}`)
      .then(handleHttpStatusCode)
      .then(payload => ({
        pagination: Pagination.fromJson(payload),
        content: payload.content.map(item => CreditAdviceSummary.fromJson(item))
      }))
  }

  getCreditAdvice(vendorKey, serialNumber) {
    return httpClient
      .get(`${CreditAdviceService.URL_MAPPING}/${vendorKey}/${serialNumber}`)
      .then(handleHttpStatusCode)
      .then(data => CreditAdvice.fromJson(data));
  }

  createCreditAdvice(createCreditAdviceCmd) {
    return httpClient
      .post(`${CreditAdviceService.URL_MAPPING}`, createCreditAdviceCmd)
      .then(handleHttpStatusCode);
  }

  addCreditLineItem(vendorKey, serialNumber, purchase) {
    const addCreditLineItemCmd = {
      dam: purchase.assetId.dam,
      damAssetId: purchase.assetId.damAssetId,
      purchaseOrdinal: purchase.assetId.purchaseOrdinal,
      tenant: purchase.assetId.tenant
    };
    return httpClient
      .post(`${CreditAdviceService.URL_MAPPING}/${vendorKey}/${serialNumber}`, addCreditLineItemCmd)
      .then(handleHttpStatusCode);
  }

  async addCreditLineItems(creditAdvice, purchases) {
    let failureCounter = 0;

    let candidates = [...purchases];
    while (candidates.length) {
      let purchase = candidates.pop()

      try {
        await this.addCreditLineItem(creditAdvice.id.vendorKey, creditAdvice.id.serialNumber, purchase)
      } catch (err) {
        failureCounter++;
      }
    }
    return failureCounter;
  }

  deleteCreditLineItem(vendorKey, serialNumber, purchase) {
    const deleteCreditLineItemCmd = {
      purchaseOrdinal: purchase.purchaseOrdinal,
      damAssetId: purchase.assetId.originDamId,
      dam: purchase.assetId.originDam,
      tenant: purchase.assetId.tenantId
    };
    return httpClient
      .delete(`${CreditAdviceService.URL_MAPPING}/${vendorKey}/${serialNumber}`, deleteCreditLineItemCmd)
      .then(handleHttpStatusCode);
  }

  async deleteCreditLineItems(creditAdvice, purchases) {
    let failureCounter = 0;

    let candidates = [...purchases];
    while (candidates.length) {
      let purchase = candidates.pop()

      try {
        await this.deleteCreditLineItem(creditAdvice.id.vendorKey, creditAdvice.id.serialNumber, purchase)
      } catch (err) {
        failureCounter++;
      }
    }

    return failureCounter;
  }

  modifyStatus(vendorKey, serialNumber, state) {
    return httpClient
      .put(`${CreditAdviceService.URL_MAPPING}/${vendorKey}/${serialNumber}/status/${state}`)
      .then(handleHttpStatusCode)
      .then(payload => CreditAdvice.fromJson(payload));
  }

  async bulkModifyStatus(creditAdvices, state, statusUpdateCallback) {
    let failureCounter = 0
    let loopCounter = 0
    while (loopCounter < creditAdvices.length) {
      let creditAdvice = creditAdvices[loopCounter]
      try {
        console.log(`Processing credit advice ${loopCounter + 1} of ${creditAdvices.length}`);
        creditAdvice = await this.modifyStatus(creditAdvice.aggregateId.vendorKey, creditAdvice.aggregateId.serialNumber, state)
        console.log(`Updated status: ${creditAdvice.status}`);

        statusUpdateCallback(creditAdvice.id.serialNumber, creditAdvice.status);

      } catch (err) {
        failureCounter++
      }
      loopCounter++
    }
    return failureCounter
  }

  modifyPersonOfContact(vendorKey, serialNumber, personOfContact = null) {
    let modifyPersonOfContactCommand = {
      vendorKey,
      serialNumber,
      personOfContact
    };

    return httpClient
      .put(`${CreditAdviceService.URL_MAPPING}/${vendorKey}/${serialNumber}/personOfContact`, modifyPersonOfContactCommand)
      .then(handleHttpStatusCode);
  }

  modifyCaption(vendorKey, serialNumber, modifyCaptionCommand) {

    return httpClient
      .put(`${CreditAdviceService.URL_MAPPING}/${vendorKey}/${serialNumber}/purchaseCaption`, modifyCaptionCommand)
      .then(handleHttpStatusCode);
  }

  fetchVendorsForCreditAdvices(query = new URLSearchParams()) {
    if (!(query instanceof URLSearchParams)) {
      throw new Error('query must be instance of URLSearchParams');
    }

    return httpClient
      .get(`${CreditAdviceService.URL_MAPPING}/vendors?${query}`)
      .then(handleHttpStatusCode)
      .then(response =>
        response || []
      );
  }
}

export const creditAdviceService = new CreditAdviceService();
